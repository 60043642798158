export enum VerticalEnum {
  SalusCore = "SalusCore",
  MentalHealth = "MentalHealth",
  Nutrition = "Nutrition",
  PhysicalHealth = "PhysicalHealth",
  PrimaryCare = "PrimaryCare",
  Motherhood = "Motherhood",
  Parenthood = "Parenthood",
  Family = "Family",
  Career = "Career",
  Sport = "Sport",
}

export enum VerticalEnrollmentState {
  Enrolled = "Enrolled",
  MatchingInProgress = "MatchingInProgress",
  MatchedWithProfessional = "MatchedWithProfessional",
}

export class HeltiaBase {
  toJSON(): Object {
    return Object.assign({}, this);
  }
}
export class Loading extends HeltiaBase {
  title: string;
  description: string;
  show: boolean;

  constructor(title: string, description: string, show: boolean) {
    super();
    this.title = title;
    this.description = description;
    this.show = show;
  }
}

export class Error extends HeltiaBase {
  message: string;
  on: string;

  constructor(message: string, on: string) {
    super();
    this.message = message;
    this.on = on;
  }
}

export class Token {
  token: string;
  expires: Date;

  constructor(token: string, expires: Date) {
    this.token = token;
    this.expires = expires;
  }
}

export class HeltiaApiError extends HeltiaBase {
  code: number;
  message: string | null;
  origin: Object | null;

  constructor(code: number, message: string | null, origin: Object | null) {
    super();
    this.code = code;
    this.message = message;
    this.origin = origin;
  }
}
