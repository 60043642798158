import { defineStore } from "pinia";
import { Buffer } from "buffer";

import {
  emailSignup,
  otpRequest,
  otpVerification,
  googleSsoCallback,
  appleSsoCallback,
  getUser,
  login,
  logOut,
  refreshAuthToken,
} from "~/helpers/api/auth";
import Tracking from "~/utils/tracking";
import SecurityUtils from "~/utils/security";
import { AuthFlow, User, UserRole } from "~/model/user";
import { AuthenticationResponse } from "~/model/auth/authenticationResponse";
import { HeltiaApiError, Token } from "~/model/common";
import { setSession } from "~/utils/session";
import { forgotPassword } from "~/helpers/api/user";
import { useMessagingStore } from "../messaging";
import { registerUserDevice } from "~/helpers/api/messaging";

declare var AppleID: any;

const usePrivateAuthStore = defineStore("privateAuth", {
  state: () => ({
    gAuthOptions: null,
    gapi: null,
    auth2: null,
    status: "initial",
    appleNonce: null,
  }),
});

export const useAuthStore = defineStore("auth", {
  state: () => ({
    form: { email: "", password: "", repassword: "" },
    forgotPasswordForm: { email: "" },
    sent: false,
    viewState: "initial",
    isLoading: false,
    authResponse: null as AuthenticationResponse | null,
    user: null as User | null,
    refreshToken: { token: null, expires: null },
    token: { token: null, expires: null },
    userAlreadyRegistered: null,
    isUserVerified: null,
    otpCode: ["", "", "", "", "", ""],
    combinedOtp: "",
    otpValidationErrors: [],
    isGoogleAuthReady: false,
    isAppleAuthReady: false,
    authFlow: null as AuthFlow | null,
    authErrorResponse: null as HeltiaApiError | null,
    SignalR: null,
    isInitialized: false,
  }),
  getters: {
    formValidation(state): any {
      return {
        isCharLimitOk: state.form.password?.length >= 8,
        includesNumber: /\d/gm.test(state.form.password),
        isMatched: state.form.password === state.form.repassword,
        isPasswordValid: state.form.password.length > 0,
        isValid:
          state.form.password.length > 0 &&
          state.form.password === state.form.repassword,
        isEmailValid: /^[+\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(
          state.form.email
        ),
      };
    },
    forgotPasswordFormValidation(state): any {
      return {
        isEmailValid: /^[+\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(
          state.forgotPasswordForm.email
        ),
      };
    },
    emailValidationErrors(state): string[] {
      var errors = [
        state.form.email.length === 0 ? "E-posta adresi boş olamaz" : null,
        this.formValidation.isEmailValid ? null : "Geçersiz e-posta adresi",
      ];

      return errors?.filter((x) => x && x.length > 0);
    },
    forgotPasswordEmailValidationErrors(state): string[] {
      var errors = [
        state.forgotPasswordForm.email.length === 0
          ? "E-posta adresi boş olamaz"
          : null,
        this.forgotPasswordFormValidation.isEmailValid
          ? null
          : "Geçersiz e-posta adresi",
      ];

      return errors?.filter((x) => x && x.length > 0);
    },
    passwordValidationErrors(state): string[] {
      var errors = [
        state.form.password.length === 0 ? "Parola boş olamaz" : null,
      ];

      return errors?.filter((x) => x && x.length > 0);
    },
    repasswordValidationErrors(state): string[] {
      var errors = [
        state.form.repassword.length === 0 ? "Parola tekrarı boş olamaz" : null,
        this.formValidation.isMatched ? null : "Parolalar eşleşmiyor",
      ];

      return errors?.filter((x) => x && x.length > 0);
    },
    otpValidation(state) {
      return {
        isValid: state.combinedOtp.length === 6,
      };
    },
    getUserType: (state) => {
      return state.user?.userType;
    },
    getIsAuthenticated: (state) => {
      return state.token?.token != null;
    },
    getUserRole: (state) => {
      if (!state.user) {
        return null;
      }

      return state.user?.userType === "Client"
        ? UserRole.Client
        : UserRole.Provider;
    },
    getAuthErrorMessage: (state) => {
      if (state.authErrorResponse === null) {
        return "";
      }
      let code = state.authErrorResponse.code;
      if (code === 10) {
        return "E-posta adresinizi sistemde bulamadık";
      } else if (code === 11) {
        return "Bu e-posta adresi başka bir yöntem ile kayıtlı";
      } else if (code === 12) {
        return "E-posta adresiniz ve şifreniz uyuşmamaktadır";
      } else if (code === 13 || code === 14) {
        return "Bu e-posta adresi sistemde kayıtlı";
      } else {
        return "Bir hata oluştu";
      }
    },
    getIsInitialized: (state) => {
      return state.isInitialized;
    },
  },
  actions: {
    async login(email: string, password: string) {
      await login(email, password).then((response) => {
        this.refreshToken = {
          token: response.refreshToken,
          expires: response.refreshTokenExpire,
        };
        this.token = {
          token: response.token,
          expires: response.tokenExpire,
        };
        this.user = {
          id: response.id,
          firstname: response.firstname,
          lastname: response.lastname,
          email: response.email,
          userState: response.userState,
        } as User;
      });
    },
    decode(plain: any) {
      return Buffer.from(plain).toString("base64");
    },
    encode(decoded: any) {
      return Buffer.from(decoded, "base64").toString();
    },
    setFormProp(prop: any) {
      this.form = { ...this.form, ...prop };
    },
    async connectSignalR(token) {
      if (this.SignalR != null) await this.SignalR.leave();

      const signalRBaseUrl =
        useRuntimeConfig().signalrBaseUrl ||
        useRuntimeConfig().public.signalrBaseUrl;
      this.SignalR = new HeltiaSignalR(signalRBaseUrl, "webapphub");
      await this.SignalR.connect();
    },
    async googleAuthenticationInit() {
      if (process.client) {
        const privateAuth = usePrivateAuthStore();

        privateAuth.gAuthOptions = {
          clientId:
            "301540793515-tikn80fua4uop2lpeeel0hj9ukffc99p.apps.googleusercontent.com",
          prompt: "select_account",
          fetch_basic_profile: true,
        };

        privateAuth.gapi = await new Promise((resolve, reject) => {
          import("gapi-script").then(({ gapi }) => {
            gapi.load("auth2", async () => {
              try {
                privateAuth.auth2 = gapi.auth2.init(privateAuth.gAuthOptions);
                privateAuth.status = "ready";
                resolve(privateAuth.auth2);
              } catch (error) {
                reject(error);
              }
            });
          });
        });

        this.isGoogleAuthReady = privateAuth.gapi !== null;
      }
    },
    async appleAuthenticationInit() {
      if (process.client) {
        /// Generates a Random String from 1-9 and A-Z characters.
        const privateAuth = usePrivateAuthStore();
        privateAuth.appleNonce = SecurityUtils.generateNonce();
        const redirectUri =
          useRuntimeConfig().public.baseUrl + routes.signup.path;

        AppleID.auth.init({
          clientId: "com.heltia.www",
          scope: "name email",
          redirectURI: redirectUri,
          state: "state",
          nonce: privateAuth.appleNonce,
          usePopup: true,
        });

        this.isAppleAuthReady = AppleID.auth !== null;
      }
    },
    async appleSignup() {
      this.isLoading = true;
      const mixpanel: any = useMixpanel();

      try {
        if (process.client) {
          const privateAuth = usePrivateAuthStore();
          var appleAuth = await new Promise((resolve, reject) => {
            document.removeEventListener("AppleIDSignInOnSuccess", null);
            document.removeEventListener("AppleIDSignInOnFailure", null);

            document.addEventListener("AppleIDSignInOnSuccess", (data: any) => {
              resolve(data.detail.authorization);
            });

            document.addEventListener(
              "AppleIDSignInOnFailure",
              (error: any) => {
                reject(error);
              }
            );

            AppleID.auth.signIn();
          });

          if (appleAuth != null) {
            this.authResponse = (await appleSsoCallback(
              appleAuth["id_token"],
              privateAuth.appleNonce
            )) as any;
            if (this.authResponse) {
              mixpanel.track("SSO Logged in", { Provider: "Apple" });

              this.setAuthTokens();

              this.user = await getUser();
              mixpanel.setUser(this.user);

              this.isUserVerified = true;

              this.userAlreadyRegistered =
                this.authResponse.justCreated === false;
              if (this.userAlreadyRegistered)
                mixpanel.track("SSO User already registered", {
                  Provider: "Apple",
                });
            }
          }
        }
      } catch (error) {
        console.error(error);
      }

      if (this.authResponse?.justCreated) {
        const deviceId = getDeviceId();

        await registerUserDevice(deviceId);
      }
      this.isLoading = false;
    },
    async googleSignup() {
      this.isLoading = true;
      const mixpanel: any = useMixpanel();

      try {
        if (process.client) {
          var privateAuthStore = usePrivateAuthStore();
          var googleUser = await new Promise((resolve, reject) => {
            privateAuthStore.auth2
              .signIn()
              .then((googleUser) => {
                resolve(googleUser);
              })
              .catch((error: any) => {
                reject(error);
              });
          });

          if (googleUser != null) {
            this.authResponse = (await googleSsoCallback(
              googleUser["xc"]["id_token"]
            )) as any;
            if (this.authResponse) {
              mixpanel.track("SSO Logged in", { Provider: "Google" });

              this.setAuthTokens();

              this.user = await getUser();
              mixpanel.setUser(this.user);
              this.isUserVerified = true;

              this.userAlreadyRegistered =
                this.authResponse.justCreated === false;
              if (this.userAlreadyRegistered)
                mixpanel.track("SSO User already registered", {
                  Provider: "Google",
                });
            }
          }
        }
      } catch (e) {
        console.error("Handle the error", e);
      }

      if (this.authResponse?.justCreated) {
        const deviceId = getDeviceId();

        await registerUserDevice(deviceId);
      }

      this.isLoading = false;
    },
    async emailSignup() {
      this.isLoading = true;
      const tracking = Tracking.getInstance();

      try {
        this.authResponse = (await emailSignup(
          this.form.email,
          this.form.password,
          this.form.repassword
        )) as any;
        if (this.authResponse) {
          tracking.signup("Successful");

          this.setAuthTokens();

          this.user = await getUser();
          tracking.setUser(this.user);

          this.viewState = "otp";
          this.requestOtp();
          this.resetTemporaryData();
        } else {
          tracking.signup("Failed");
        }
      } catch (e) {
        tracking.signup("Failed");
        this.userAlreadyRegistered = true;
        if (e.response.data.data) {
          this.authErrorResponse = Object.assign({}, e.response.data.data);
        }
      }

      const deviceId = getDeviceId();
      await registerUserDevice(deviceId);
      this.isLoading = false;
    },
    async emailSignin() {
      this.isLoading = true;
      const tracking = Tracking.getInstance();

      try {
        const response = await login(this.form.email, this.form.password);
        this.authResponse = response;
        if (response) {
          tracking.login("Successful");

          this.setAuthTokens();
          this.user = await getUser();
          tracking.setUser(this.user);
          this.userAlreadyRegistered = true;
        } else {
          tracking.login("Failed");
        }
      } catch (e) {
        if (e.response.data.data) {
          this.authErrorResponse = Object.assign({}, e.response.data.data);
        }
        tracking.login("Failed");
      }
      this.isLoading = false;
    },
    async authenticationResponseSignin(
      authenticationResponse: AuthenticationResponse
    ) {
      const tracking = Tracking.getInstance();

      this.authResponse = authenticationResponse;

      tracking.login("Successful from Meeting OTC");

      this.setAuthTokens();
      try {
        this.user = await getUser();
        tracking.setUser(this.user);
      } catch (e) {
        if (e.response.data.data) {
          this.authErrorResponse = Object.assign({}, e.response.data.data);
        }
        tracking.login("Failed");
      }
    },
    async logoutApiCall() {
      const deviceId = getDeviceId();

      try {
        await logOut(this.refreshToken.token, deviceId);
      } catch (e) {
        console.error(e);
        if (e?.response?.status === 400) {
          const refreshResponse = await refreshAuthToken(
            this.refreshToken.token
          );
          const newToken: Token = {
            token: refreshResponse.token,
            expires: refreshResponse.tokenExpire,
          };

          const newRefreshToken: Token = {
            token: refreshResponse.refreshToken,
            expires: refreshResponse.refreshTokenExpire,
          };

          this.syncAuthStoreAndSession(newToken, newRefreshToken);
          await logOut(this.refreshToken.token, deviceId);
        }
      }
    },
    async logout() {
      this.authResponse == null;
      this.user = null;
      this.token = { token: null, expires: null };
      this.refreshToken = { token: null, expires: null };
      const messagingStore = useMessagingStore();
      await messagingStore.logout();
      const tracking = Tracking.getInstance();
      tracking.clearUser();
      LocalStorageUtil.clear();
      clearSession();
      await clearDexieDatabase();
    },
    requestOtp() {
      const tracking = Tracking.getInstance();
      tracking.authentication("OTP requested");
      this.otpValidationErrors = [];
      otpRequest(this.user.email)
        .then((x) => {
          this.isUserVerified = null;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    otpCodeChanged(index: number) {
      this.otpCode[index] = this.otpCode[index].toString().slice(-1);
      if (this.combinedOtp != this.otpCode.join("")) {
        this.isUserVerified = null;
        this.combinedOtp = this.otpCode.join("");
      }
    },
    async onVerifyOtpCode() {
      this.isLoading = true;
      this.otpValidationErrors = [];
      try {
        var result = (await otpVerification(
          this.user.email,
          this.combinedOtp
        )) as any;
        this.isUserVerified =
          result.error === undefined || result.error === null;
        if (!this.isUserVerified) {
          if (result.error.message === "Incorrect verification parameters") {
            this.otpValidationErrors = [
              "Girdiğiniz kod yanlış, lütfen tekrar deneyin",
            ];
          } else if (result.error.message === "TTL timeout") {
            this.otpValidationErrors = [
              "Girdiğiniz kodun süresi geçmiş, lütfen tekrar deneyin",
            ];
          }
        } else {
          await this.refreshUser();
        }
        const tracking = Tracking.getInstance();
        tracking.authentication("OTP confirmed");
      } catch (e) {
        this.isUserVerified = false;
      }
      this.isLoading = false;
    },
    changeViewState(viewState: string) {
      this.viewState = viewState;
    },
    clearState() {
      this.form = { email: "", password: "", repassword: "" };
      this.forgotPasswordForm = { email: "" };
      this.authErrorResponse = null;
    },
    clearOtp() {
      this.otpCode = ["", "", "", "", "", ""];
      this.combinedOtp = "";
    },
    resetViewState() {
      this.viewState = "initial";
    },
    async refreshUser(doNotForceToLogout = false) {
      try {
        var newUser = await getUser(doNotForceToLogout);
        if (newUser) {
          this.user = newUser;
        }
      } catch {}
    },
    setAuthTokens(updateLocalStorage = true) {
      const refreshToken = new Token(
        this.authResponse.refreshToken,
        this.authResponse.refreshTokenExpire
      );

      const token = new Token(
        this.authResponse.token,
        this.authResponse.tokenExpire
      );

      this.refreshToken = refreshToken;
      this.token = token;

      setSession(token, refreshToken, updateLocalStorage);
      this.connectSignalR(token.token);
    },
    syncAuthStoreAndSession(token: Token, refreshToken: Token) {
      this.refreshToken = refreshToken;
      this.token = token;

      setSession(token, refreshToken, true);
      this.isUserVerified = true;
      this.connectSignalR(token.token);
    },
    setAuthFlow(authFlow: AuthFlow) {
      this.authFlow = authFlow;
    },
    isSignedIn() {
      return this.token.token !== null;
    },
    isAuthFlowHighway() {
      return this.authFlow === AuthFlow.Highway;
    },
    isAuthFlowSet() {
      return this.authFlow !== null;
    },
    async initialize() {
      const hasToken = LocalStorageUtil.getItem(LocalStorageKeys.Token);
      if (!hasToken) {
        this.isInitialized = true;
        return;
      }

      await this.loadUser();

      this.isInitialized = true;
    },
    async loadUser() {
      if (this.user == null) {
        this.isLoading = true;
        try {
          this.user = (await getUser()) as any;
        } catch (e) {}
        this.isLoading = false;
      }
    },
    resetTemporaryData() {
      this.resetAuthErrorResponse();
      this.userAlreadyRegistered = null;
      this.clearState();
      this.clearOtp();
    },
    resetAuthErrorResponse() {
      this.authErrorResponse = null;
    },
    hasGoogleSso() {
      return (
        this.authErrorResponse !== null &&
        this.authErrorResponse.message.includes("GOOGLE")
      );
    },
    hasAppleSso() {
      return (
        this.authErrorResponse !== null &&
        this.authErrorResponse.message.includes("APPLE")
      );
    },
    async onForgotPassword() {
      this.isLoading = true;
      try {
        await forgotPassword(this.forgotPasswordForm.email);
      } catch (e) {}
      this.isLoading = false;
    },
    getAccessToken() {
      return this.token?.token;
    },
  },
});
